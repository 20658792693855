import React, { useEffect, useState } from "react";
import useSWR from "swr";
import "../App.css";
import { getItems } from "../services/getItems";
import { useLocation } from "react-router-dom";
import TransactionTable from "./list/Table";
import { formatDate, getLast9CharsOfId } from "../utils";

const transformTransactions = (items = []) => {
  if (!Array.isArray(items)) {
    console.error("Expected an array but received:", items);
    return [];
  }

  return items.map((transaction) => ({
    amount: transaction?.price ? `$${transaction.price}` : "N/A",
    transactionId: getLast9CharsOfId(transaction?._id) || "N/A",
    date: transaction?.createdAt
      ? formatDate(transaction.createdAt).split(" ")[0]
      : "N/A",
    time: transaction?.createdAt
      ? formatDate(transaction.createdAt).split(" ")[1]
      : "N/A",
  }));
};

const fetcher = async (url, page, limit, sort) => {
  const response = await getItems(page, limit, sort);
  return response.data;
};

const RecordTable = () => {
  // const { loader, onFetchBalance } = useAuth();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState({
    price: "asc",
    date: "asc",
  });
  const location = useLocation();
  // SWR fetch hook
  const { data, error, isLoading } = useSWR(
    ["items", currentPage, limit, sort],
    ([_, page, limit, sort]) => fetcher("/api/items", page, limit, sort),
    { revalidateOnFocus: false }
  );

  const items = data?.items || [];
  const totalPages = data?.totalPages || 0;

  // useEffect(() => {
  //   onFetchBalance();
  // }, [currentPage, limit, sort, location.pathname]);


  const handleSort = (key) => {
    setSort((prevState) => ({
      ...prevState,
      [key]: prevState[key] === "asc" ? "desc" : "asc",
    }));
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <TransactionTable
      data={transformTransactions(items)}
      currentPage={currentPage}
      totalPages={totalPages}
      onPageChange={handlePageChange}
      title={"Stripe Transactions"}
      isLoading={isLoading}
    />
  );
};

export default RecordTable;
