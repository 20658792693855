import axiosInstance from "../interceptor/axiosInstance";

export const getPaymentStatus = async (intentId) => {
  try {
    const response = await axiosInstance.get(`/payment-status/${intentId}`);
    return response.data;
  } catch (error) {
    alert(error);
  }
};
