import React, { useState } from "react";
import "./Header.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

const Header = () => {
  const location = useLocation();
  const isLoggedIn = location.pathname.includes("recordlist");
  const navigate = useNavigate();
  const { onLogout, balance } = useAuth();


  const handleLogin = () => {
    // Simulate login action (replace with real authentication logic)
    navigate('/login')
  };

  const handleLogout = () => {
    // Simulate logout action (replace with real authentication logic)
    onLogout();
    navigate('/login')
  };

  return (
    <div className="header">
      <div className="logo-container">
        <img src="/GSE_LOGO.svg" alt="Logo" className="login-logo-header" onClick={() => navigate('/')}/>
      </div>
      <div className="right-container">
        {isLoggedIn && (
          <div className="account-balance-container">
            <span className="account-text">Account Balance</span>
            <span className="account-amount">${balance}</span>
          </div>
        )}
        {isLoggedIn ? (
          <button className="login-button-header" onClick={handleLogout}>
            Logout
          </button>
        ) : (
          <button className="login-button-header" onClick={handleLogin}>
            Login
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
