import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Tabs, Modal , Button, Input } from 'antd';
import "../App.css";

import RecordTable from "../components/ItemsTable";
import VisaRecordTable from "../components/VisaItemsTable";

const { TabPane } = Tabs;

const HomePage = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const tableType = searchParams.get("type") || "stripe";
  const handleTabChange = (key) => {
    setSearchParams({ type: key });
  };

  useEffect(() => {
    if (!tableType) {
      // If no type exists in the query, default to 'visa'
      setSearchParams({ type: "stripe" });
    }
  }, [tableType, setSearchParams]);
  return (
    <>
      <Link to="/stripe" style={styles.addButton}>
        Add Record Item
      </Link>

      <>
        <Tabs activeKey={tableType} onChange={handleTabChange} centered>
          <TabPane tab="Visa Record Table" key="visa">
            <VisaRecordTable />
          </TabPane>
          <TabPane tab="Stripe Record Table" key="stripe">
            <RecordTable />
          </TabPane>
        </Tabs>
      </>
     {/* <RecordTable/> */}
    </>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    height: "75vh",
  },

  addButton: {
    backgroundColor: "#4A90E2",
    color: "#fff",
    border: "none",
    padding: "12px 25px",
    borderRadius: "25px",
    cursor: "pointer",
    fontSize: "18px",
    transition: "all 0.3s ease",
    boxShadow: "0 4px 10px rgba(74, 144, 226, 0.4)",
    marginBottom: "20px",
  },

  tableContainer: {
    marginTop: "30px",
    textAlign: "center",
    width: "100%",
    padding: "0 10px",
    boxSizing: "border-box",
  },
  tableHeading: {
    fontSize: "22px",
    color: "#4A90E2",
    marginBottom: "15px",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    backgroundColor: "#fff",
    boxShadow: "0 6px 15px rgba(0, 0, 0, 0.1)",
    borderRadius: "10px",
    overflow: "hidden",
  },
  tableHeader: {
    backgroundColor: "#4A90E2",
    color: "#fff",
    padding: "12px",
    fontWeight: "bold",
    fontSize: "16px",
  },
  tableCell: {
    padding: "12px",
    borderBottom: "1px solid #ddd",
    textAlign: "center",
    fontSize: "15px",
  },

  paginationContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    marginTop: "20px",
  },
  paginationButton: {
    backgroundColor: "#fff",
    color: "#4A90E2",
    border: "none",
    padding: "10px 10px",
    cursor: "pointer",
    fontSize: "16px",
    transition: "all 0.3s ease",
    borderRadius: "10px",
    width: "40px",
    height: "40px",
    textAlign: "center",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
  activePaginationButton: {
    backgroundColor: "#4A90E2",
    color: "#fff",
    border: "1px solid #4A90E2",
    cursor: "default",
  },
  disabledPaginationButton: {
    opacity: "0.6",
    cursor: "not-allowed",
  },
};

export default HomePage;
