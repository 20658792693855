import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import StripePaymentSuccess from "./StripePaymentSuccess";

export default function StripeCheckoutForm({ dpmCheckerLink, item }) {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [status, setStatus] = useState("");

  const [isSuccess, setIsSuccess] = useState(false);
  const [intentId, setIntentId] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });

      if (error) {
        setMessage(error.message);
      } else if (paymentIntent && paymentIntent.status) {
        setIntentId(paymentIntent.id);
        setStatus(paymentIntent.status);
        setIsSuccess(true);
      } else {
        setMessage("Payment status is unclear. Please try again.");
      }
    } catch (err) {
      setMessage("An unexpected error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const paymentElementOptions = {
    layout: "accordion",
  };

  return (
    <>
      {!isSuccess ? (
        <div>
          <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement
              id="payment-element"
              options={paymentElementOptions}
            />

            <button
              className="login-button"
              // onClick={handlePayment}
              disabled={isLoading || !stripe || !elements}
            >
              {isLoading ? "Paying..." : "Pay Now"}
            </button>
            {/* <button disabled={isLoading || !stripe || !elements} id="submit">
              <span id="button-text">
                {isLoading ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  "Pay now"
                )}
              </span>
            </button> */}
            {message && (
              <div className="payment-message">
                <div className="message-content">{message}</div>
              </div>
            )}
          </form>
          <div id="dpm-annotation">
            <p>
              Payment methods are dynamically displayed based on customer
              location, order amount, and currency.&nbsp;
              <a
                href={dpmCheckerLink}
                target="_blank"
                rel="noopener noreferrer"
                id="dpm-integration-checker"
              >
                Preview payment methods by transaction
              </a>
            </p>
          </div>
        </div>
      ) : (
        <StripePaymentSuccess intentId={intentId} status={status} />
      )}
    </>
  );
}
