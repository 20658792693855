import axiosInstance from "../interceptor/axiosInstance";

export const getItems = async (page, limit, sort) => {
  try {
    const response = await axiosInstance.get(
      `/api/showItems?page=${page}&limit=${limit}&price=${sort.price}&date=${sort.date}`
    );
    return response;
  } catch (error) {
    alert(error);
  }
};
