import React from "react";
import "./TableLayout.css";

const TableLayout = ({ children }) => {

  return (
    <>
      
      <div className="table-container">
        {/* Background Image Overlay */}
        <img
          src="/backgroundImage.jpeg"
          alt="Background Shades"
          className="table-background-image"
        />

        {children}
      </div>
    </>
  );
};

export default TableLayout;
