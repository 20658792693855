import React from "react";
import LoginCard from "../components/login/Card";
import PageLayout from "./Layout";
import { useAuth } from "../context/AuthContext";
import { Navigate } from "react-router-dom";

const LoginPage = () => {
    const { loader, token } = useAuth();
    if (!loader && token) {
      return <Navigate to={"/recordlist"} />;
    }
  return (
    <PageLayout>
      <LoginCard />
    </PageLayout>
  );
};

export default LoginPage;
