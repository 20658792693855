import { Route, Routes } from "react-router-dom";
import "./App.css";
import "./style.css";
import  { Toaster } from 'react-hot-toast';
import HomePage from "./pages/home";
import Stripe from "./pages/stripe";
import LoginPage from "./pages/Login";
import PrivateRoute from "./components/PrivateRoute";
import { useAuth } from "./context/AuthContext";
import LoadingSpinner from "./components/LoadingSpinner";
// import VisaPage from "./pages/VisaPage";
import CreateRecordPage from "./pages/CreateRecordPage";
import List from "./pages/List";

function App() {
  const { loader } = useAuth();

  if (loader) return <LoadingSpinner />;
  return (
    <div style={{}}>
      {/* <Header /> */}
      <Routes>
        <Route path="/" element={<CreateRecordPage />} />
        <Route
          path="/recordlist"
          element={
            <PrivateRoute>
              {/* <HomePage /> */}
              <List/>
            </PrivateRoute>
          }
        />
        {/* <Route path="/visa" element = {<VisaPage/>}/> */}
        <Route path="/stripe" element={<Stripe />} />
        <Route path="/login" element={<LoginPage/>} />
      </Routes>
      <Toaster />
    </div>
  );
}


export default App;
