import React, { useState } from "react";
import { login } from "../../services/auth";
import toast from "react-hot-toast";
import "./login.css";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";

const LoginCard = ( ) => {
  const { onLogin } = useAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({ password: "" });
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.password) {
      toast.error("Please enter a password.");
      return;
    }

    setLoading(true);
    try {
      const response = await login(formData);
      if (response?.token) {
          onLogin(response.token);
          toast.success("Login successful!");
          navigate(`/recordlist`);
      } else {
        toast.error("Invalid credentials. Please try again.");
      }
    } catch (err) {
      toast.error("An error occurred. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="login-card">
      {/* Logo */}
      <img src="/GSE_LOGO.svg" alt="Logo" className="login-logo" />

      {/* Heading */}
      <h2 className="login-title">LOGIN</h2>

      {/* Password Input Field */}
      <div className="form-group">
        <label>Password</label>
        <input
          type="password"
          name="password"
          className="input-field"
          placeholder="Enter Password"
          value={formData.password}
          onChange={handleInputChange}
          disabled={loading}
        />
      </div>

      {/* Login Button */}
      <button className="login-button" onClick={handleSubmit} disabled={loading}>
        {loading ? "Logging in..." : "Login"}
      </button>

      {/* Create Button */}
      <button className="create-button margin-bottom" onClick= {() => navigate("/")}disabled={loading}>Create</button>
    </div>
  );
};

export default LoginCard;
