import axiosInstance from "../interceptor/axiosInstance";

export const getBalance = async () => {
  try {
    const response = await axiosInstance.get(`/api/balance`);
    return response;
  } catch (error) {
    alert(error);
  }
};
