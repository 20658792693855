import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import LoadingSpinner from "./LoadingSpinner";

const PrivateRoute = ({ children }) => {
  const { loader, token } = useAuth();

  if (loader) return <LoadingSpinner />;

  if (!loader && token) {
    return children;
  }
  if (!loader && !token) {
    return <Navigate to="/login" />;
  }
};

export default PrivateRoute;
