import React from "react";
import "./transactionTable.css";

const TransactionTable = ({ data, currentPage, totalPages, onPageChange, title, isLoading = false, rowsPerPage = 10 }) => {
  return (
    <div className="transaction-container">
      {/* Header Row */}
      <div className="transaction-header">
        <h2 className="transaction-title">{title ?? "Transactions"}</h2>
        <img src="/FILTER_ICON.svg" alt="Filter" className="filter-icon" />
      </div>

      {/* Table Wrapper with Loading Effect */}
      <div className={`table-wrapper ${isLoading ? "loading" : ""}`}>
        {isLoading && (
          <div className="loading-overlay">
            <div className="modern-spinner"></div>
          </div>
        )}
        <table className="transaction-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Amount</th>
              <th>Transaction ID</th>
              <th>Date</th>
              <th>Time</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(data) && data.length > 0 ? (
              data.map((transaction, index) => (
                <tr key={index}>
                  <td>{(currentPage - 1) * rowsPerPage + index + 1}</td>
                  <td>{transaction.amount || "N/A"}</td>
                  <td>{transaction.transactionId || "N/A"}</td>
                  <td>{transaction.date || "N/A"}</td>
                  <td>{transaction.time || "N/A"}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="no-data-message">No transactions available.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination */}
      {data.length > 0 && !isLoading && (
        <div className="transaction-pagination">
          <span className="pagination-info">
            Page {currentPage} of {totalPages}
          </span>
          <div className="pagination-buttons">
            <button
              className="pagination-btn"
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <button
              className="pagination-btn"
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionTable;
