import React from "react";
import { useLocation } from "react-router-dom"; // Import useLocation
import "./LoginPage.css";
import Header from "../components/Header";

const PageLayout = ({ children }) => {
  const location = useLocation(); // Get current route
  const shouldRenderHeader = !location.pathname.includes("login") && !location.pathname.includes("recordlist")
  const containerClass = location.pathname.includes("login") ? "login-page-container": "login-container"
  return (
    <>
      {/* Conditionally render Header only if not on /login */}
      {shouldRenderHeader && <Header />}
      
      <div className={containerClass}>
        {/* Background Image Overlay */}
        <img
          src="/backgroundImage.jpeg"
          alt="Background Shades"
          className="background-image"
        />

        {children}
      </div>
    </>
  );
};

export default PageLayout;
