import React from "react";
import CreateCard from "../components/login/CreateCard";
import PageLayout from "./Layout";

const CreateRecordPage = () => {
  return (
    <PageLayout>
      <CreateCard />
    </PageLayout>
  );
};

export default CreateRecordPage;
