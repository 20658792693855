import { useSearchParams } from "react-router-dom";
import Header from "../components/Header";
import TransactionTable from "../components/list/Table";
import TableLayout from "../components/list/TableLayout";
import TabSwitcher from "../components/list/TabSwitcher";
import VisaRecordTable from "../components/VisaItemsTable";
import RecordTable from "../components/ItemsTable";

const List = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const tableType = searchParams.get("type") || "stripe";
  return (
    <>
      <Header />
      <TabSwitcher />
      <TableLayout>
        {/* {tableType === "visa" && (<VisaRecordTable/>)} */}
        {tableType === "stripe" && (<RecordTable/>)}
      {/* <TransactionTable/> */}
      </TableLayout>
    </>
  );
};

export default List;
