import { useState } from "react";
import StripeContainer from "../components/StripeContainer";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import PageLayout from "./Layout";

const Stripe = () => {
  const [item, setItem] = useState(getItemDetails);
  const navigate = useNavigate();

  // Fetch item details from local storage safely
  function getItemDetails() {
    try {
      const storedData = localStorage.getItem("itemDetails");
      return storedData ? JSON.parse(storedData) : null;
    } catch (error) {
      console.error("Error parsing local storage data:", error);
      return null;
    }
  }

  useEffect(() => {
    if (!item?.price) {
      toast.error(
        "No price field added. Please go to the main page and add the price!"
      );
      navigate("/");
    }
  }, [item, navigate]);

  return (
    <>
      <PageLayout>
        {item && (
          <StripeContainer storedAmount={+item.price * 100} item={item} />
        )}
      </PageLayout>
    </>
  );
};

export default Stripe;
