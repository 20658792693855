import React, { useState } from "react";
import toast from "react-hot-toast";
import "./login.css";
import { useNavigate } from "react-router-dom";

const CreateCard = () => {
  const [formData, setFormData] = useState({
    name: "Tarmac",
    price: "",
  });

  const navigate = useNavigate();


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleProceed = (path) => {
    if (formData.name && formData.price) {

        localStorage.setItem("itemDetails", JSON.stringify(formData));
  
        navigate(path);
  
        setFormData({ name: "", price: "" });
      } else {
      toast.error("Please fill out the price field!");
      }
  };

  return (
    <div className="login-card">

      {/* Heading */}
      <h2 className="login-title">ADD RECORD ITEM</h2>

      {/* Record Name Input Field */}
      <div className="form-group">
        <label>Record Name</label>
        <input
          type="text"
          name="name"
          className="input-field"
          value={formData.name}
          readOnly
        />
      </div>

      {/* Price Input Field */}
      <div className="form-group">
        <label>Price (USD)</label>
        <input
          type="number"
          name="price"
          className="input-field"
          placeholder="Enter Price"
          value={formData.price}
          onChange={handleInputChange}
        />
      </div>

      {/* Proceed with Visa Button */}
      {/* <button className="create-button" onClick={() => handleProceed("/visa")}>Proceed With Visa</button> */}

      {/* Proceed with Stripe Button */}
      <button className="create-button margin-bottom" onClick={() => handleProceed("/stripe")}>Proceed With Stripe</button>
    </div>
  );
};

export default CreateCard;
