import React, { useState } from "react";
import "./tabSwitcher.css";
import { useSearchParams } from "react-router-dom";

const TabSwitcher = ( ) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const activeTab = searchParams.get("type") || "stripe";
    const handleTabChange = (key) => {
      setSearchParams({ type: key });
    };
  

  return (
    <div className="tab-container">
      <div className="tab-wrapper">
        {/* <span
          className={`tab-button ${activeTab === "visa" ? "active" : ""}`}
          onClick={() => handleTabChange("visa")}
        >
        
        <img src="/VISA_LOGO.svg" alt="Visa Logo" className="tab-logo-visa margin-left-logo" />
         Visa Record Table
          {activeTab === "visa" && <div className="tab-underline"></div>}
        </span> */}
        <span
          className={`tab-button ${activeTab === "stripe" ? "active" : ""}`}
          onClick={() => true || handleTabChange("stripe")}
        >
          <img src="/STRIPE_LOGO.svg" alt="Visa Logo" className="tab-logo margin-left-logo" />
           Stripe Record Table
          {activeTab === "stripe" && <div className="tab-underline"></div>}
        </span>
      </div>
    </div>
  );
};

export default TabSwitcher;