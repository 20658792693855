export const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
  
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  export const getLast9CharsOfId = (id = "") => {
    if (typeof id !== "string" || id.length < 9) {
      console.error("Invalid Mongoose ID:", id);
      return "N/A"; // Return a default value to prevent crashes
    }
    return id.slice(-15);
  };
  