import React, { createContext, useContext, useEffect, useState } from "react";
import { getBalance } from "../services/getBalance";

const intial = {
  token: null,
  onLogin: (data) => {},
  onLogout: () => {},
  onFetchBalance: () => {},
  onUpdateBalance: () => {},

  loader: true,
  balance: 0,
};

const AuthContext = createContext(intial);

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [loader, setLoader] = useState(true);
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
      handleBalance();
    } else {
      localStorage.clear();
      setToken(null);
    }
    setLoader(false);
  }, []);

  const login = (tkn) => {
    localStorage.setItem("token", tkn);
    setToken(tkn);
  };

  const handleBalance = async () => {
    const response = await getBalance();
    setBalance(response?.data?.balance || 0);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setToken(null);
  };

  const handleUpdateBalance = (amount) => {
    setBalance((prevState) => prevState + amount);
  };

  return (
    <AuthContext.Provider
      value={{
        onLogin: login,
        onLogout: logout,
        token,
        loader,
        balance,
        onFetchBalance: handleBalance,
        onUpdateBalance: handleUpdateBalance,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
